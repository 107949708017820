var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "account-content" },
    [
      _c("h2", { staticClass: "h4" }, [_vm._v("02. Gameplay Wallet")]),
      _vm._m(0),
      _vm._m(1),
      _vm._m(2),
      _c("b-form", { staticClass: "form mb-3" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-6" }, [
            _c("p", [_vm._v("Amount:")])
          ]),
          _c(
            "div",
            { staticClass: "col-md-2" },
            [
              _c(
                "b-form-group",
                [
                  _c("b-form-input", {
                    attrs: { number: "", required: "", min: "5", max: "1000" },
                    model: {
                      value: _vm.funds,
                      callback: function($$v) {
                        _vm.funds = $$v
                      },
                      expression: "funds"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "col-md-2" }, [
            _c(
              "div",
              { staticClass: "form-group select-wrapper" },
              [
                _c("b-form-select", {
                  staticClass: "no-touch",
                  attrs: { options: _vm.currencies, plain: "" },
                  model: {
                    value: _vm.currency,
                    callback: function($$v) {
                      _vm.currency = $$v
                    },
                    expression: "currency"
                  }
                })
              ],
              1
            )
          ])
        ])
      ]),
      _c("div", { attrs: { id: "paypal-button-container" } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h6", { staticClass: "mt-5 mb-5" }, [_c("u", [_vm._v("Wallet")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row justify-content-between" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c("p", [_vm._v("Current Amount:")])
      ]),
      _c("div", { staticClass: "col-md-6" }, [_c("h5", [_vm._v("54$")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h6", { staticClass: "mt-5 mb-5" }, [
      _c("u", [_vm._v("Add Buget")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }