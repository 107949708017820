<template>
  <div class="account-content">
		<h2 class="h4">02. Gameplay Wallet</h2>
    <h6 class="mt-5 mb-5"><u>Wallet</u></h6>
		<div class="row justify-content-between">
			<div class="col-md-6">
				<p>Current Amount:</p>
			</div>
			<div class="col-md-6">
				<h5>54$</h5>
			</div>
		</div>
		<h6 class="mt-5 mb-5"><u>Add Buget</u></h6>
		<b-form class="form mb-3">
			<div class="row">
				<div class="col-md-6">
					<p>Amount:</p>
				</div>
				<div class="col-md-2">
					<b-form-group>
						<b-form-input
							v-model="funds"
							number
							required
							min=5
							max=1000 />
					</b-form-group>
				</div>
				<div class="col-md-2">
					<div class="form-group select-wrapper">
						<b-form-select :options="currencies" v-model="currency" class="no-touch" plain>
						</b-form-select>
					</div>
				</div>
			</div>
		</b-form>
    <div id="paypal-button-container"></div>
  </div>
</template>

<script>
export default {
	mounted() {
		window.paypal.Buttons({
			locale: 'en_US',
			style: {
				size: 'responsive',
				color: 'black',
				shape: 'rect',
				label: 'paypal',
				tagline: false,
			},

			// Set up the transaction
			createOrder(data, actions) {
				return actions.order.create({
					purchase_units: [{
						amount: {
							value: 5,
						},
					}],
				});
			},

			payment(data, actions) {
				return actions.payment.create({
					transactions: [{
						amount: {
							total: '11',
							currency: 'USD',
						},
					}],
				});
			},

			// Finalize the transaction
			onApprove(data, actions) {
				return actions.order.capture().then((details) => {
					// Show a success message to the buyer
					this.$toasted.info(`Transaction completed by ${details.payer.name.given_name}!`);
				});
			},
		}).render('#paypal-button-container');
	},
	data() {
		return {
			funds: 5,
			currencies: [
				'EUR',
				'USD',
			],
			currency: 'EUR',
		};
	},
};
</script>

<style>

</style>
